import React from 'react';
import WorkProvider from './WorkContext/WorkrProvider';
import Work from './Work';
import { Outlet } from 'react-router-dom';

export const WorkWrapper = () => {

  return (
    <WorkProvider>
      <Outlet />
    </WorkProvider>
  )

}
