import React from 'react';
import WorksWidget from './components/WorksWidget';
import OffersWidget from './components/OffersWidget';
import { PartsEmptyEconomicValuesWidget } from './components/parts/PartsEmptyEconomicValuesWidget';
import { PartsLowPriceWidget } from './components/parts/PartsLowPriceWidget';
import { PartsLowMarginWidget } from './components/parts/PartsLowMarginWidget';

function Dashboard() {
  return (
    <div>

      <div className='row'>
        <div className="col-12 col-xl-4 d-flex align-items-stretch">
          <PartsEmptyEconomicValuesWidget/>
        </div>
        <div className="col-12  col-xl-4 d-flex align-items-stretch">
          <PartsLowPriceWidget/>
        </div>
        <div className="col-12 col-xl-4 d-flex align-items-stretch">
          <PartsLowMarginWidget/>
        </div>
      </div>

      <div className='row'>
        <div className='col-lg-6'>
          <WorksWidget/>
        </div>
        <div className='col-lg-6'>
          <OffersWidget/>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
