import React, { useContext, useEffect, useState } from 'react';
import { WorkContext } from '../Work/WorkContext/WorkContext';
import { PageHeader } from '../../../components/UI/Page/PageHeader';
import { useTranslation } from 'react-i18next';
import { range } from 'lodash';
import Select from 'react-select';
import { reactSelectStyles } from '../../../configs/reactSelect.config';
import worksService from '../../../services/works/worksService';
import { toast } from 'react-toastify';
import SubWorkDiff from './components/SubWorkDiff';
import { getTextClassName, subWorkItemStatesValues } from './components/diffHelper';

export default function WorkRevisions() {

  const { work } = useContext(WorkContext)
  const { t } = useTranslation()

  const [currentRevision, setCurrentRevision] = useState(work?.sub_works ?? undefined);
  const [loadedRevision, setLoadedRevision] = useState(undefined);
  const [options, setOptions] = useState([])
  const [selectedOption, setSelectedOption] = useState(undefined)
  const [diffResult, setDiffResult] = useState(undefined)


  useEffect(() => {
    setCurrentRevision(work?.sub_works ?? undefined)
    setOptions(() => work ? range(1, work?.revision).map((i) => ({ value: i, label: `${t('works.revision')} ${i}` })) : [])
  }, [work])

  useEffect(() => {
    if (selectedOption) {
      worksService.fetchRevision(work.uuid, selectedOption.value)
      .then((data) => setLoadedRevision(data))
      .catch((err) => {
        setLoadedRevision(undefined)
        toast.error(err?.response?.data?.message)
      })
    } else {
      setLoadedRevision(undefined)
    }
  }, [selectedOption]);

  const getSubWorkForDiff = subWork => {
    return {
      qty: subWork.qty,
      is_enabled: subWork.is_enabled
    }
  }


  const getItemForDiff = item => {
    return {
      code: item.code,
      description: item.description,
      qty: item.qty,
      handle_qty: item.handle_qty,
      mm: item.mm,
      position: item.position
    }
  }


  useEffect(() => {
      if (loadedRevision && currentRevision) {
        let result = [];

        currentRevision.forEach(subWork => {
          let items = {};

          subWork.items.forEach(item => {
            items[item.code] = {
              current: getItemForDiff(item),
              selected: null
            };
          });

          result[subWork.position] = {
            code: subWork.code,
            position: subWork.position,
            current: getSubWorkForDiff(subWork),
            selected: null,
            items: items
          };
        });

        loadedRevision.forEach(subWork => {
          if (result[subWork.position]) {
            result[subWork.position].selected = getSubWorkForDiff(subWork);

            subWork.items.forEach(item => {
              if (result[subWork.position].items[item.code]) {
                result[subWork.position].items[item.code].selected = getItemForDiff(item);
              } else {
                result[subWork.position].items[item.code] = {
                  code: item.code,
                  current: null,
                  selected: getItemForDiff(item)
                };
              }
            });
          } else {
            let items = {};

            subWork.items.forEach(item => {
              items[item.code] = {
                code: item.code,
                current: null,
                selected: getItemForDiff(item)
              };
            });

            result[subWork.position] = {
              code: subWork.code,
              position: subWork.position,
              current: null,
              selected: getSubWorkForDiff(subWork),
              items: items
            };
          }
        });

        setDiffResult(result);
      } else {

        setDiffResult(undefined);
      }
    }, [loadedRevision, currentRevision]
  );

  return work && currentRevision && <>
    <PageHeader title={`${work.number} Rev. ${work.revision}` } subTitle={work.description}>
      <div className="flex flex-row items-center">
        Compare la revisione corrente con:
        <Select
          className='bg-white'
          styles={reactSelectStyles}
          placeholder={t('works.revision')}
          options={options}
          isClearable={true}
          value={selectedOption}
          onChange={(value) => setSelectedOption(value)}
        />
      </div>
    </PageHeader>

    {diffResult
      ? (
        <div>

          <div className='mb-3'>
          {subWorkItemStatesValues.map((state) => (
            <div key={state}>
                  <span className={getTextClassName(state)}>
                    {t('sub_work_items.states.' + state)}
                  </span>:&nbsp;
              {t('sub_work_items.states.' + state + '_description')}
            </div>
          ))}
          </div>

          {Object.keys(diffResult).map((key) => <SubWorkDiff key={key} subWork={diffResult[key]}/>)}

        </div>) : (
        <>Seleziona una revisione</>
      )}
  </>

}
