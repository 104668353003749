import React from 'react';
import { useRequest } from 'ahooks';
import mediaService from '../../../../services/media/mediaService';
import MediaList from '../../../../components/Media/MediaList';
import { toast } from 'react-toastify';
import MediaDropzone from '../../../../components/Media/MediaDropzone';
import SweetAlert from 'sweetalert2';
import { useTranslation } from 'react-i18next';

const PartMedia = ({ part }) => {

  const { t } = useTranslation()
  const { data = [], mutate } = useRequest(mediaService.fetchAll, {
    defaultParams: [part?.id, part?.model],
    refreshDeps: [part?.id],
    ready: part?.id
  });

  const getMediaByCollection = collection => _.get(_.groupBy(data, 'collection'), collection, []);
  const handleUpload = res => mutate(d => [...d, res])
  const handleDownload = (media, preview = false) => {
    if(preview) {
      mediaService.download(part.uuid, part.model, media)
    } else {
      mediaService.downloadToHardDisk(part.uuid, part.model, media)
    }
  }
  const handleDelete = (media) => SweetAlert.fire({
    text: t('are_you_sure'),
    icon: 'warning',
    showCancelButton: true,
  }).then((response) => {
      if (response.isConfirmed) {
        mediaService.destroy(part.id, part.model, media.id)
        .then(() => mutate(d => d.filter(m => m.id !== media.id)))
        .catch((err) => toast.error(err?.response?.data?.message));
      }
    }
  )

  const handleDownloadCollection = collection => mediaService.downloadZippedCollection(part.id, part.model, collection, `${part.code}_${collection}.zip`)
  .catch(err => toast.error(err?.response?.data?.message))

  return (
    <div className='card w-100'>
      <div className='card-body'>
        <div className='text-black-50'>Documenti tecnici {part?.code}</div>
        <MediaList
          media={getMediaByCollection('vault')}
          onDownloadCollection={() => handleDownloadCollection('vault')}
          title='Documentazione Tecnica'
          onDownload={handleDownload}
          onDelete={handleDelete}
          canDelete={false}
        />
        <MediaList
          media={getMediaByCollection('downloads')}
          onDownloadCollection={() => handleDownloadCollection('downloads')}
          title='Downloads'
          onDownload={handleDownload}
          onDelete={handleDelete}
          canDelete={true}
        />
        <MediaList
          media={getMediaByCollection('default')}
          onDownloadCollection={() => handleDownloadCollection('default')}
          title='Media'
          onDownload={handleDownload}
          onDelete={handleDelete}
          canDelete={true}
        />
        <MediaDropzone
          modelId={part.id}
          modelType={part.model}
          onSuccess={handleUpload}
        />
      </div>
    </div>
  )

}

export default PartMedia
