import PartTypeEnum from '../../../../constants/enums/PartTypeEnum';
import { useTranslation } from 'react-i18next';
import { CardLabel } from '../../../../components/UI/Card/CardLabel';
import React from 'react';
import { CardBody } from '../../../../components/UI/Card/CardBody';
import { Card } from '../../../../components/UI/Card/Card';
import { useRequest } from 'ahooks';
import widgetsService from '../../../../services/widgetsService';
import { find } from 'lodash';
import { FormDisplay } from '../../../../components/UI/Displays/FormDisplay';
import { DisplayCount } from './DisplayCount';

const partTypes = [
  PartTypeEnum.values.WITHOUT_MEASURE,
  PartTypeEnum.values.WITH_MEASURE,
  PartTypeEnum.values.CUSTOM
]

export function PartsEmptyEconomicValuesWidget() {

  const { t } = useTranslation();

  const { data, loading } = useRequest(widgetsService.emptyEconomicValuesParts)

  return (
    <Card>
      <CardBody>
        <CardLabel>
          <div className='text-dark'>{t('widgets.empty_economic_values_parts_widget.title')}</div>
          {t('widgets.empty_economic_values_parts_widget.description')}
        </CardLabel>
        {loading ? 'loading...' : (
          <div className='row'>
            {PartTypeEnum.collection
            .filter(pt => partTypes.includes(pt.value))
            .map(pt => <div key={pt.value} className='col-4'>
              <FormDisplay label={t(pt.label)}>
                <DisplayCount count={find(data, d => d.part_type === pt.value)?.count ?? 0}/>
              </FormDisplay>
            </div>)
            }
          </div>
        )}
      </CardBody>
    </Card>
  )
}
