import { useEffect, useState } from 'react';
import { matchAttribute } from './diffHelper';

export default function SubWorkItemValueDiff({ attribute, current, selected, className }) {
  const [status, setStatus] = useState(undefined)

  useEffect(() => {
    setStatus(matchAttribute(current, selected, attribute))
  }, [current, selected, attribute])

  return status && (
    <td className={className ? ' ' + className : ''}>
      <div className={status.className}>
        {status.label}
      </div>
    </td>
  )


}
