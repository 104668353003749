import React from 'react';
import WorksWrapper from '../../../../pages/Works/Works/WorksWrapper';
import Works from '../../../../pages/Works/Works/Works';
import { WorkWrapper } from '../../../../pages/Works/Work/WorkWrapper';
import WorkRevisions from '../../../../pages/Works/WorkRevisions/WorkRevisions';
import Work from '../../../../pages/Works/Work/Work';

const worksRoutes = {
  path: 'works/*',
  element: <WorksWrapper/>,
  children: [{
    path: '',
    index: true,
    element: <Works/>,
  }, {
    path: ':id/*',
    element: <WorkWrapper/>,
    children: [{
      path: '',
      index: true,
      element: <Work/>
    }, {
      path: 'revisions',
      element: <WorkRevisions/>,
    }]
  }],
};

export default worksRoutes;
