import React from 'react';
import MultipleTextFilter from '../../../../components/UI/Filters/MultipleTextFilter';
import { useTranslation } from 'react-i18next';
import { get } from 'lodash';
import PartStateFilter from '../../../../components/UI/Filters/PartStateFilter';
import TextFilter from '../../../../components/UI/Filters/TextFilter';
import WorkFilter from '../../../../components/UI/Filters/WorkFilter';
import BooleanFilter from '../../../../components/UI/Filters/BooleanFilter';
import { UncontrolledTooltip } from 'reactstrap';

const CustomPartFilters = ({ handleUpdate, defaultValues }) => {

  const { t } = useTranslation()
  const [values, setValues] = React.useState(defaultValues)
  const getValue = name => get(values, name, values[name])
  const setValue = (name, value) => setValues(v => ({ ...v, [name]: value }))
  const handleSubmit = () => handleUpdate(values)

  return (
    <>
      <div className='row mb-1'>
        <div className='col-lg-3 col-md-6'>
          <div className="d-flex flex-column gap-1">
            <MultipleTextFilter
              handleSubmit={handleSubmit}
              value={getValue('code')}
              setValue={setValue}
              name='code'
              label={t('parts.code')}
            />
            <MultipleTextFilter
              handleSubmit={handleSubmit}
              value={getValue('description')}
              setValue={setValue}
              name='description'
              label={t('description')}
            />
            <TextFilter
              handleSubmit={handleSubmit}
              value={getValue('drawing_note')}
              setValue={setValue}
              name='drawing_note'
              label={t('parts.drawing_note')}
            />
          </div>
        </div>
        <div className='col-lg-3 col-md-6'>
          <div className="d-flex flex-column gap-1">
            <MultipleTextFilter
              handleSubmit={handleSubmit}
              value={getValue('width')}
              setValue={setValue}
              name='width'
              label={t('width')}
            />
            <MultipleTextFilter
              handleSubmit={handleSubmit}
              value={getValue('depth')}
              setValue={setValue}
              name='depth'
              label={t('depth')}
            />
            <MultipleTextFilter
              handleSubmit={handleSubmit}
              value={getValue('height')}
              setValue={setValue}
              name='height'
              label={t('height')}
            />
          </div>
        </div>
        <div className='col-lg-3 col-md-6 mt-md-1'>
          <div className="d-flex flex-column gap-1">
            <WorkFilter
              handleSubmit={handleSubmit}
              value={getValue('works')}
              setValue={setValue}
              name="works"
              label={t('works.plural')}
            />
            <PartStateFilter
              handleSubmit={handleSubmit}
              value={getValue('state')}
              setValue={setValue}
              name="state"
            />
          </div>
        </div>
        <div className='col-lg-3 col-md-6 mt-md-1'>
          <div className="d-flex flex-column gap-1">
            <BooleanFilter
              handleSubmit={handleSubmit}
              value={getValue('no_margin')}
              setValue={setValue}
              name="no_margin"
              label={t('no_margin')}
            />
            <BooleanFilter
              handleSubmit={handleSubmit}
              value={getValue('no_cost')}
              setValue={setValue}
              name="no_cost"
              label={t('no_cost')}
            />
            <BooleanFilter
              handleSubmit={handleSubmit}
              value={getValue('no_price')}
              setValue={setValue}
              name="no_price"
              label={t('no_price')}
            />
            <UncontrolledTooltip target='empty_economic_values'>
              {t('widgets.empty_economic_values_parts_widget.description')}
            </UncontrolledTooltip>
            <BooleanFilter
              handleSubmit={handleSubmit}
              value={getValue('empty_economic_values')}
              setValue={setValue}
              name="empty_economic_values"
              label={t('empty_economic_values')}
            />
            <UncontrolledTooltip target='low_price'>
              {t('widgets.low_price_parts_widget.description')}
            </UncontrolledTooltip>
            <BooleanFilter
              handleSubmit={handleSubmit}
              value={getValue('low_price')}
              setValue={setValue}
              name="low_price"
              label={t('low_price')}
            />
            <UncontrolledTooltip target='low_margin'>
              {t('widgets.low_margin_parts_widget.description')}
            </UncontrolledTooltip>
            <BooleanFilter
              handleSubmit={handleSubmit}
              value={getValue('low_margin')}
              setValue={setValue}
              name="low_margin"
              label={t('low_margin')}
            />
          </div>
        </div>
      </div>
      <div className='d-flex align-items-center justify-content-end gap-2 mb-3'>
        <button className='btn btn-primary' type='button' onClick={handleSubmit}>
          <i className='fa fa-filter'/>&nbsp;{t('search')}
        </button>
      </div>
    </>
  )

}

export default CustomPartFilters
