import clsx from 'clsx';

export function DisplayCount({ count = 0 }) {

  return (
    <div className={clsx(
      'fs-4 fw-bold',
      count > 0 && 'text-danger',
      count === 0 && 'text-success'
    )}>
      {count}
    </div>
  )

}
