import React, { useContext } from 'react';
import mediaService from '../../../../../services/media/mediaService';
import { toast } from 'react-toastify';
import MediaDropzone from '../../../../../components/Media/MediaDropzone';
import SweetAlert from 'sweetalert2';
import { useTranslation } from 'react-i18next';
import WorkMediaVaultList from './WorkMediaVaultList';
import MediaList from '../../../../../components/Media/MediaList'
import { WorkContext } from '../../WorkContext/WorkContext';
import { workActionTypes } from '../../WorkContext/workReducer/workActionTypes';
import { Card } from '../../../../../components/UI/Card/Card';
import { CardBody } from '../../../../../components/UI/Card/CardBody';
import { CardLabel } from '../../../../../components/UI/Card/CardLabel';

const WorkMedia = () => {

  const { t } = useTranslation()
  const { media, work, dispatchAction } = useContext(WorkContext)
  const getMediaByCollection = collection => _.get(_.groupBy(media, 'collection'), collection, []);
  const handleUpload = res => dispatchAction({ type: workActionTypes.STORE_MEDIA, payload: res })
  const handleDownload = (media, preview = false) => {
    if(preview) {
      mediaService.download(work.uuid, work.model, media)
    } else {
      mediaService.downloadToHardDisk(work.uuid, work.model, media)
    }
  }
  const handleDelete = (media) => SweetAlert.fire({
    text: t('are_you_sure'),
    icon: 'warning',
    showCancelButton: true,
  }).then((response) => {
    if (response.isConfirmed) {
      mediaService.destroy(work.id, work.model, media.id)
      .then(res => dispatchAction({ type: workActionTypes.DELETE_MEDIA, payload: media.id }))
      .catch((err) => toast.error(err?.response?.data?.message));
    }
  });

  const getFileName = production => production ? `production_${work.number}.pdf` : `customer_${work.number}.pdf`
  const handleDownloadMergedPdf = production => mediaService.downloadWorkMergedPdf(work.uuid, getFileName(production), production)
  const handleDownloadCollection = collection => mediaService.downloadZippedCollection(work.id, work.model, collection, `${work.number}_${collection}.zip`)
  .catch(err => toast.error(err?.response?.data?.message))
  const handleDownloadColorConfigsPdf = () => mediaService.downloadColorConfigsPdf(work.uuid, `${work.number}_color_configs.pdf`)
  const handleDownloadBomsPdf = () => mediaService.downloadBomsPdf(work.uuid, `${work.number}_boms.pdf`)

  return (
    <>
      <Card>
        <CardBody>
          <CardLabel>Documenti tecnici di progetto</CardLabel>
          <WorkMediaVaultList
            media={getMediaByCollection('vault')}
            onDownload={handleDownload}
            onDelete={handleDelete}
            onDownloadMerged={handleDownloadMergedPdf}
            onDownloadColorConfig={handleDownloadColorConfigsPdf}
            onDownloadBomsPdf={handleDownloadBomsPdf}
          />
          <MediaList
            media={getMediaByCollection('downloads')}
            title='Downloads'
            canEdit
            onDownload={handleDownload}
            onDownloadCollection={() => handleDownloadCollection('downloads')}
            onDelete={handleDelete}
            canDelete={true}
          />
          <MediaList
            media={getMediaByCollection('default')}
            title='Media'
            canEdit
            onDownload={handleDownload}
            onDownloadCollection={() => handleDownloadCollection('default')}
            onDelete={handleDelete}
            canDelete={true}
          />

          <MediaDropzone
            modelId={work.id}
            modelType={work.model}
            onSuccess={handleUpload}
          />
        </CardBody>
      </Card>
    </>
  )

}

export default WorkMedia
