import React, { useContext } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import PartPicker from '../../../../../../components/UI/Forms/Pickers/PartPicker';
import { OfferContext } from '../../../offerContext/offerContext';
import subOfferItemsService from '../../../../../../services/offers/subOfferItemsService';
import SubOfferItemTypeEnum from '../../../../../../constants/enums/SubOfferItemTypeEnum';
import { toast } from 'react-toastify';
import { AppContext } from '../../../../../../components/AppContext';
import * as Yup from 'yup';
import { useTranslation } from 'react-i18next';
import FormGroup from '../../../../../../components/UI/Forms/FormGroup';
import { FormActions } from '../../../../../../components/UI/Forms/FormActions';
import { FormNumber } from '../../../../../../components/UI/Forms/FormNumber';

const validationSchema = Yup.object().shape({
  part_id: Yup.number().nullable().required(),
  qty: Yup.number().min(1).required(),
})

const SubOfferItemPartAdder = ({ subOfferUuid, offerRevisionUuid, setIsNewClicked, partTypes, action }) => {

  const { t } = useTranslation()
  const initialValues = { part: "", qty: 1 };
  const { offer, dispatchAction } = useContext(OfferContext)
  const { setLoadingOverlayState } = useContext(AppContext)

  const handleSubmit = (values, { setSubmitting, resetForm }) => {
    setLoadingOverlayState(true)

    subOfferItemsService.store(offer.uuid, offerRevisionUuid, subOfferUuid, {
      'part_id': values.part_id,
      'qty': values.qty,
      'type': SubOfferItemTypeEnum.PART
    }).then(res => {
      resetForm();
      setIsNewClicked(false)
      dispatchAction({
        type: action,
        payload: {
          subOfferItem: res,
          subOfferUuid: subOfferUuid
        }
      })
    }).catch(err => toast.error(err?.response?.data?.message))
    .finally(() => {
      setSubmitting(false)
      setLoadingOverlayState(false)
    })
  }

  const formik = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: handleSubmit
  })

  return (
    <FormikProvider value={formik}>
      <Form>
        <div className='row'>
          <div className="col-10">
            <FormGroup label={t('parts.sing')} name="part_id" required inline={false}>
              <PartPicker
                handleChange={value => formik.setFieldValue('part_id', value?.id)}
                defaultParams={{ part_type: partTypes }}
              />
            </FormGroup>
          </div>
          <div className="col-2">
            <FormGroup label={t('offers.qty')} name="qty" inline={false}>
              <FormNumber
                name='qty'
                value={formik.values.qty}
                step={1}
                min={1}
              />
            </FormGroup>
          </div>
        </div>
        <FormActions
          withReset={false}
          onCancel={() => setIsNewClicked(null)}
        />
      </Form>
    </FormikProvider>
  )

}

export default SubOfferItemPartAdder
