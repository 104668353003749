import React from 'react';

const BooleanFilter = ({ name, label, value, setValue, handleSubmit }) => {

  const handleKeyDown = event => {
    if (event.key === 'Enter') {
      handleSubmit()
    }
  };

  return (
    <div className="form-check">
      <input
        onKeyDown={handleKeyDown}
        type="checkbox"
        className="form-check-input"
        name={name}
        id={name}
        value={1}
        checked={value}
        onChange={e => setValue(name, e.target.checked)}
      />
      <label className='d-flex flex-column gap-1'>
        {label}
      </label>
    </div>
  );
}

export default BooleanFilter;
