import SubWorkItemDiff from './SubWorkItemDiff';
import { useTranslation } from 'react-i18next';
import { useEffect, useState } from 'react';
import { matchAttribute } from './diffHelper';
import { Card } from '../../../../components/UI/Card/Card';
import { CardBody } from '../../../../components/UI/Card/CardBody';

export default function SubWorkDiff({ subWork }) {

  const { t } = useTranslation()

  const [status, setStatus] = useState(undefined)

  useEffect(() => {
    setStatus(matchAttribute(subWork.current, subWork.selected, 'qty'))
  }, [subWork])

  return (
    <Card>
      <CardBody>


        <div className='d-flex align-items-center justify-content-start gap-4'>
          <div className='fw-bold fs-4'>{subWork.code}</div>
          <div className={`fw-bold fs-5 ${status?.className}`}>
            {t('sub_works.qty')}&nbsp;{status?.label}
          </div>
        </div>


        <table className='table table-sm' style={{ verticalAlign: 'middle' }}>
          <tbody>

          <tr className='fw-bold'>
            <td>{t('state')}</td>
            <td>{t('sub_work_items.code')}</td>
            <td>{t('sub_work_items.description')}</td>
            <td className='text-center'>{t('sub_work_items.qty')}</td>
            <td className='text-center'>{t('sub_work_items.handle_qty')}</td>
            <td className='text-center'>{t('sub_work_items.mm')}</td>
            <td className='text-center'>{t('sub_work_items.position')}</td>
          </tr>

          {Object.keys(subWork.items).map((key) => (
            <SubWorkItemDiff key={key} item={subWork.items[key]}/>
          ))}

          </tbody>
        </table>
      </CardBody>
    </Card>
  )

}
