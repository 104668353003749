import DateDisplay from '../Displays/DateDisplay';
import DestroyButtonIcon from '../Buttons/Destroy/DestroyButtonIcon';
import { Button, UncontrolledTooltip } from 'reactstrap';
import React from 'react';

export const ListItemFile = ({ media, onDownload, canDelete = true, onDelete }) => {
  return (
    <li className='px-0 list-group-item d-flex flex-column flex-sm-row justify-content-sm-between align-items-sm-center gap-sm-4 gap-2'>

      <div className='' title={media.name}>
        <span className='fw-bold text-primary'>{media.name.slice(0, 15)}</span>
        <div className='small d-flex align-items-center justify-content-start gap-2'>
          <span>{media.size}</span>
          <span><DateDisplay value={media.created_at} formatString='dd/MM/yyyy HH:mm:ss'/></span>
          <span className={`text-uppercase fw-bold text`}>{media.extension}</span>
        </div>
      </div>

      <div className='d-flex align-items-center justify-content-start gap-2'>
        <UncontrolledTooltip target={`download_item_${media.uuid}`}>
          Scarica il file
        </UncontrolledTooltip>
        <UncontrolledTooltip target={`preview_item_${media.uuid}`}>Apri nel browser</UncontrolledTooltip>
        <Button
          id={`download_item_${media.uuid}`}
          className='btn btn-soft-secondary btn-icon'
          type='button'
          onClick={() => onDownload(media, false)}>
          <i className='fa fa-arrow-down'/>
        </Button>
        <Button
          id={`preview_item_${media.uuid}`}
          className='btn btn-soft-secondary btn-icon'
          type='button'
          onClick={() => onDownload(media, true)}
        >
          <i className='fa fa-eye'/>
        </Button>
        {canDelete && <DestroyButtonIcon onClick={() => onDelete(media)}/>}
      </div>
    </li>
  )

}

