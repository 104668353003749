import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import SubWorkItemValueDiff from './SubWorkItemValueDiff';
import { matchItem } from './diffHelper';

export default function SubWorkItemDiff({ item }) {

  const { t } = useTranslation()
  const [status, setStatus] = useState(undefined)

  useEffect(() => {
    setStatus(matchItem(item))
  }, [item])

  return (
    <tr>
      <td className={status?.className}>
        {t(status?.label)}
      </td>
      <SubWorkItemValueDiff
        current={item.current}
        selected={item.selected}
        attribute='code'
      />
      <SubWorkItemValueDiff
        current={item.current}
        selected={item.selected}
        attribute='description'
      />
      <SubWorkItemValueDiff
        current={item.current}
        selected={item.selected}
        attribute='qty'
        className='text-center'
      />
      <SubWorkItemValueDiff
        current={item.current}
        selected={item.selected}
        attribute='handle_qty'
        className='text-center'
      />
      <SubWorkItemValueDiff
        current={item.current}
        selected={item.selected}
        attribute='mm'
        className='text-center'
      />
      <SubWorkItemValueDiff
        current={item.current}
        selected={item.selected}
        attribute='position'
        className='text-center'
      />
    </tr>
  )


}
