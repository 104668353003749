import PartTypeEnum from '../../../../constants/enums/PartTypeEnum';
import { useTranslation } from 'react-i18next';
import { useRequest } from 'ahooks';
import widgetsService from '../../../../services/widgetsService';
import { Card } from '../../../../components/UI/Card/Card';
import { CardBody } from '../../../../components/UI/Card/CardBody';
import { CardLabel } from '../../../../components/UI/Card/CardLabel';
import { FormDisplay } from '../../../../components/UI/Displays/FormDisplay';
import { DisplayCount } from './DisplayCount';
import { find } from 'lodash';
import React from 'react';

const partTypes = [
    PartTypeEnum.values.WITHOUT_MEASURE,
    PartTypeEnum.values.WITH_MEASURE,
    PartTypeEnum.values.CUSTOM
]

export function PartsLowPriceWidget() {

    const { t } = useTranslation();

    const { data, loading } = useRequest(widgetsService.lowPriceParts)

    return (
      <Card>
          <CardBody>
              <CardLabel>
                <div className='text-dark'>{t('widgets.low_price_parts_widget.title')}</div>
                {t('widgets.low_price_parts_widget.description')}
              </CardLabel>
              {loading ? 'loading...' : (
                <div className='row'>
                    {PartTypeEnum.collection
                    .filter(pt => partTypes.includes(pt.value))
                    .map(pt => <div key={pt.value} className='col-4'>
                        <FormDisplay label={t(pt.label)}>
                            <DisplayCount count={find(data, d => d.part_type === pt.value)?.count ?? 0}/>
                        </FormDisplay>
                    </div>)
                    }
                </div>
              )}
          </CardBody>
      </Card>
    )
}
