import React, { useContext } from 'react';
import WorkUsers from './components/WorkUsers/WorkUsers';
import WorkCustomers from './components/WorkCustomers/WorkCustomers';
import WorkPartners from './components/WorkPartners/WorkPartners';
import WorkSapOrders from './components/WorkSapOrders/WorkSapOrders';
import ColorConfigs from '../../../components/ColorConfigs/ColorConfigs';
import WorkMedia from './components/WorkMedia/WorkMedia';
import WorkSubWorks from './components/WorkSubWorks/WorkSubWorks';
import colorConfigsService from '../../../services/colors/colorConfigsService';
import WorkDetails from './components/WorkDetails/WorkDetails';
import { WorkContext } from './WorkContext/WorkContext';
import { workActionTypes } from './WorkContext/workReducer/workActionTypes';
import WorkProductionStateChanger from './components/WorkStates/WorkProductionStateChanger';
import WorkStateChanger from './components/WorkStates/WorkStateChanger';
import WorkCodesChecker from './components/WorkCodesChecker/WorkCodesChecker';
import WorkStateHistory from "./components/WorkStates/WorkStateHistory";
import UserMessages from '../../../components/UserMessages/UserMessages';
import { PageHeader } from '../../../components/UI/Page/PageHeader';
import { CardActions } from '../../../components/UI/Card/CardActions';
import { Card } from '../../../components/UI/Card/Card';
import { CardBody } from '../../../components/UI/Card/CardBody';
import WorkStateEnum from '../../../constants/enums/WorkStateEnum';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const Work = () => {

  const { work, dispatchAction, color_parts } = useContext(WorkContext)
  const {t} = useTranslation()

  return work ? (
    <>
      <PageHeader title={work.number} subTitle={work.description}>
        <CardActions>
          <WorkStateChanger/>
          <WorkProductionStateChanger/>
          <WorkStateHistory/>
        </CardActions>
      </PageHeader>

      <div className='d-flex flex-column gap-4'>

        <div className='row'>
          <div className="col-xl-3 col-lg-4 ">
            <div className='row'>
              <div className='col-md-6 col-lg-12 d-flex d-lg-block align-items-stretch align-items-lg-start'>
                <WorkDetails/>
              </div>
              <div className='col-md-6 col-lg-12 d-flex d-lg-block align-items-stretch align-items-lg-start'>
                <WorkCustomers/>
              </div>
              <div className='col-md-6 col-lg-12 d-flex d-lg-block align-items-stretch align-items-lg-start'>
                <WorkPartners/>
              </div>
              <div className='col-md-6 col-lg-12 d-flex d-lg-block align-items-stretch align-items-lg-start'>
                <WorkUsers/>
              </div>
              <div className='col-md-6 col-lg-12 d-flex d-lg-block align-items-stretch align-items-lg-start'>
                <WorkSapOrders/>
              </div>
            </div>
          </div>

          <div className="col-xl-9 col-lg-8 ">
            <div className='row'>
              <div className="col-xl-6 col-lg-12 d-flex align-items-stretch">
                <WorkMedia/>
              </div>
              <div className="col-xl-6 col-lg-12 d-flex align-items-stretch">
                <WorkCodesChecker/>
              </div>
            </div>

            <div className='row'>
              <div className="col-xl-6 col-lg-12 d-flex align-items-stretch">
                <WorkSubWorks/>
              </div>
              <div className="col-xl-6 col-lg-12 d-flex align-items-stretch">
                <Card>
                  <CardBody>
                    <ColorConfigs
                      canEdit={work.state.value === WorkStateEnum.values.negotiation || work.state.value === WorkStateEnum.values.standby}
                      serviceParams={work.uuid}
                      serviceCall={colorConfigsService.storeWorkConfig}
                      colorParts={color_parts}
                      colorConfigs={work.color_configs}
                      onUpdate={res => dispatchAction({
                        type: workActionTypes.UPDATE_COLOR_CONFIGS,
                        payload: res
                      })}
                    />
                  </CardBody>
                </Card>
              </div>
            </div>

            <UserMessages
              model_type={work.model}
              model_id={work.id}
            />

          </div>


        </div>
      </div>
    </>
  ) : <></>


}

export default Work
