const ADDED = 'added';
const CHANGED = 'changed';
const UNCHANGED = 'unchanged';
const REMOVED = 'removed';

export const subWorkItemStatesValues = [
  ADDED,
  CHANGED,
  UNCHANGED,
  REMOVED
]

/**
 *
 * @param state
 * @returns {string}
 */
export function getTextClassName(state) {
  switch (state) {
    case ADDED:
      return 'text-success';
    case REMOVED:
      return 'text-danger';
    case CHANGED:
      return 'text-warning';
    case UNCHANGED:
      return 'text-primary';
    default:
      return '';
  }
}

/**
 * Get label for the attribute
 * @param current
 * @param selected
 * @returns {string}
 */
function getLabel(current, selected) {
  if (current === null) {
    return selected || 'n/a';
  }

  if (selected === null) {
    return current || 'n/a';
  }

  return `${current} -> ${selected}`;
}

/**
 * @param item
 * @returns {{label: string}}
 */
export function matchItem(item) {
  if (item.current === null) {
    return {
      label: 'sub_work_items.states.' + REMOVED,
      className: getTextClassName(REMOVED),
      state: REMOVED,
    }
  } else if (item.selected === null) {
    return {
      label: 'sub_work_items.states.' + ADDED,
      className: getTextClassName(ADDED),
      state: ADDED,
    }

  } else {
    if (
      item.current.qty !== item.selected.qty ||
      item.current.mm !== item.selected.mm ||
      item.current.position !== item.selected.position
    ) {
      return {
        label: 'sub_work_items.states.' + CHANGED,
        className: getTextClassName(CHANGED),
        state: CHANGED,
      }
    } else {
      return {
        label: 'sub_work_items.states.' + UNCHANGED,
        className: getTextClassName(UNCHANGED),
        state: UNCHANGED,
      }
    }
  }
}

/**
 *
 * @param current
 * @param selected
 * @param attribute
 * @returns {{label: string, className: string, state: string}|{label: (*|string), className: string, state: string}}
 */
export function matchAttribute(current, selected, attribute) {
  if (current && selected) {
    if (current[attribute] !== selected[attribute]) {
      return {
        label: getLabel(current[attribute], selected[attribute]),
        className: getTextClassName(CHANGED),
        state: CHANGED,
      }
    } else {
      return {
        label: current[attribute] || 'n/a',
        className: getTextClassName(UNCHANGED),
        state: UNCHANGED,
      }
    }
  }

  if (!current) {
    return {
      label: selected[attribute] || 'n/a',
      className: getTextClassName(REMOVED),
      state: REMOVED,
    }
  }

  if (!selected) {
    return {
      label: current[attribute] || 'n/a',
      className: getTextClassName(ADDED),
      state: ADDED,
    }
  }
}
