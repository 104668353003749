import api from '../configs/api.config';

const url = '/widgets';
const myOffers = params => api.get(`${url}/offers`, { params }).then(res => res.data);

const emptyEconomicValuesParts = () => api.get(`${url}/parts/empty-economic-values`).then(res => res.data);
const lowPriceParts = () => api.get(`${url}/parts/low-price`).then(res => res.data);
const lowMarginParts = () => api.get(`${url}/parts/low-margin`).then(res => res.data);

const widgetsService = {
  myOffers,
  emptyEconomicValuesParts,
  lowPriceParts,
  lowMarginParts
};

export default widgetsService;
