import React, { useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useRequest } from 'ahooks';
import mediaService from '../../../../services/media/mediaService';
import { OfferContext } from '../offerContext/offerContext';
import SweetAlert from 'sweetalert2';
import { toast } from 'react-toastify';
import MediaList from '../../../../components/Media/MediaList';
import MediaDropzone from '../../../../components/Media/MediaDropzone';
import offerRevisionsService from '../../../../services/offers/offerRevisionsService';
import { Card } from '../../../../components/UI/Card/Card';
import { CardBody } from '../../../../components/UI/Card/CardBody';
import { CardLabel } from '../../../../components/UI/Card/CardLabel';

const OfferRevisionMedia = () => {

  const { offer } = useContext(OfferContext)

  const { t } = useTranslation()
  const { data = [], mutate } = useRequest(offerRevisionsService.getMedia, {
    defaultParams: [offer?.uuid, offer?.offer_revision?.uuid],
    refreshDeps: [offer?.offer_revision?.id],
    ready: offer?.offer_revision?.id
  })

  const getMediaByCollection = collection => _.get(_.groupBy(data, 'collection'), collection, []);
  const handleUpload = res => mutate(d => [...d, res])
  const handleDownload = (media, preview = false) => {
    if(preview) {
      mediaService.download(offer?.offer_revision?.uuid, offer?.offer_revision?.model, media)
    } else {
      mediaService.downloadToHardDisk(offer?.offer_revision?.uuid, offer?.offer_revision?.model, media)
    }
  }

  const handleDelete = (media) => SweetAlert.fire({
    text: t('are_you_sure'),
    icon: 'warning',
    showCancelButton: true,
  }).then((response) => {
      if (response.isConfirmed) {
        mediaService.destroy(offer?.offer_revision?.id, offer?.offer_revision?.model, media.id)
        .then(() => mutate(d => d.filter(m => m.id !== media.id)))
        .catch((err) => toast.error(err?.response?.data?.message));
      }
    }
  )

  const handleDownloadCollection = collection => mediaService.downloadZippedCollection(offer.offer_revision.id,
    offer.offer_revision.model,
    collection,
    `${offer.number}_${collection}.zip`)
  .catch(err => toast.error(err?.response?.data?.message))

  return (
    <Card>
      <CardBody>
        <CardLabel>Documenti tecnici revisione {offer?.offer_revision?.revision}</CardLabel>
        {offer?.work !== null &&
        <MediaList
          media={getMediaByCollection('vault')}
          onDownloadCollection={() => handleDownloadCollection('vault')}
          title='Documentazione Tecnica'
          onDownload={handleDownload}
          onDelete={handleDelete}
          canDelete={false}
        />}
        <MediaList
          media={getMediaByCollection('downloads')}
          onDownloadCollection={() => handleDownloadCollection('downloads')}
          title='Downloads'
          onDownload={handleDownload}
          onDelete={handleDelete}
          canDelete={true}
        />
        <MediaList
          media={getMediaByCollection('default')}
          onDownloadCollection={() => handleDownloadCollection('default')}
          title='Media'
          onDownload={handleDownload}
          onDelete={handleDelete}
          canDelete={true}
        />
        <MediaDropzone
          modelId={offer?.offer_revision?.id}
          modelType={offer?.offer_revision?.model}
          onSuccess={handleUpload}
        />
      </CardBody>
    </Card>
  )

}

export default OfferRevisionMedia
